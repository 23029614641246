import React,{useState,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from "react-alert";


function EditAllergy(props) {
 
  const alert = useAlert();
  const [error,setError]=useState({
    allergyname:''
      
     });
    const[data, setData]= useState(props.data)
    const[completeShow,setCompleteShow]=useState(false)
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


const saveChanges = ()=> {
   
  if(validate()){  
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 let dataToSend={}
 if(props.data.allergy_name===data.allergy_name&&props.data.allergy_type===data.allergy_type){
  dataToSend = {                           
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
 }else if(props.data.allergy_name===data.allergy_name){
  dataToSend = {                           
    allergy_type : data.allergy_type,  
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
 }
 
 else{
   dataToSend = {
    allergy_name: data.allergy_name,                               
    allergy_type : data.allergy_type,
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
}
     axios
       .put(`${APIURL}/api/v1/staff/master-allergy-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status==="success") {
         alert.success('Saved successfully!')
         props.modalClose()
          setData({
            allergy_name : '',
            allergy_type : '',
            allergy_stage : '',
            description : ''
          });
        }
          else {
            alert.error(res.data.message)
           //setErrorShow(true)
         }
       })
       .catch((err) => {
         //setErrorShow(true)
       });
      }  //  setSuccessShow(false)
    }
  
  
    const validate=()=>{
    
      let input = data;
      let errors = {};
      let isValid = true;
    setError({});
  
  
      if (!input["allergy_name"] || input["allergy_name"]==="" ) {
        isValid = false;
        errors["allergyname"] = "Enter allergy Name";
      }
      
      if (!input["allergy_type"] || input["allergy_type"]==="" ) {
        isValid = false;
        errors["type"] = "Enter allergy type";
      }
  
       setError(currentstate=>({
             ...currentstate,
            ...errors
          }));
      
  
      return isValid;
  }
  
    

    return (
        <>
     <h4 style={{marginTop:'0'}} className="title-of-page">Edit Allergy</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='pad-features'>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-5'>*Allergy Name: </label>
                    <input type='text' className='form-control title-flex' name='allergy_name' value={data.allergy_name} onChange={handleTitle} /></div>
                    {error.allergyname ? <div className="error-validation-msg error-feature-inventory col-7">{error.allergyname}</div> : null}
                </div>
                <div className="formField" style={{textAlign:"left"}}> 
                  <div style={{display:"flex"}}>
                    <label className='col-5'>*Allergy Type: </label>
                    <select
                    name='allergy_type'
                    className="form-control title-flex"
                  
                    value={data.allergy_type}
                    onChange={handleTitle}>
                    <option value="" selected hidden  >Select</option>
                    <option value="drug" >Drug</option>  
                    <option value="food" >Food</option>  
                    <option value="environment" >Environment</option>  
                                      
                </select>
                </div>
                   
                   {error.type ? <div className="error-validation-msg error-feature-inventory col-7" style={{float:"right"}}>{error.type}</div> : null} 
                </div>
                
                <div className="formField" style={{display:"flex"}}>
                    <label className='col-5'>Allergy Stage: </label>
                    <input type='text' className='form-control title-flex' name='allergy_stage' value={data.allergy_stage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='col-5'>Description: </label>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                </div>
            </div>
            <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>    


        </div>
        </>
    )
}

export default EditAllergy
