

let backendHost;
let socketHost;

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const hostname = window && window.location && window.location.hostname;

if(hostname === 'apps.3docs.in') {
  backendHost = 'https://api.3docs.in';
  socketHost = 'wss://api.3docs.in';
} else if(hostname === 'unistage.cianlogic.com') {
  backendHost = 'https://unistageapi.cianlogic.com';
  socketHost = 'wss://unistageapi.cianlogic.com';
} else if(hostname === 'uni.cianlogic.com') {
  backendHost = 'https://uniapi.cianlogic.com';
  socketHost = 'wss://uniapi.cianlogic.com';
}
 else if(hostname === 'dndstage.cianlogic.com') {
  backendHost = 'https://dndstageapi.cianlogic.com';
  socketHost = 'wss://dndstageapi.cianlogic.com';
}

else if(hostname === 'ecare.cianlogic.com') {
  backendHost = 'https://ecareapi.cianlogic.com';
  socketHost = 'wss://ecareapi.cianlogic.com';
 } else if(hostname === 'ecarestage.cianlogic.com') {
    backendHost = 'https://ecareapistage.cianlogic.com';
    socketHost = 'wss://ecareapistage.cianlogic.com';
}else if(hostname === 'localhost') {
  backendHost = 'https://ecareapi.cianlogic.com';
  socketHost = 'wss://ecareapi.cianlogic.com';
  // backendHost = 'https://pvapi.cianlogic.com';
  // socketHost = 'wss://pvapi.cianlogic.com';
}
else if(hostname === 'pv.cianlogic.com') {
  backendHost = 'https://pvapi.cianlogic.com';
  socketHost = 'wss://pvapi.cianlogic.com';
}
else if(hostname === 'pvstage.cianlogic.com') {
  backendHost = 'https://pvstageapi.cianlogic.com';
  socketHost = 'wss://pvstageapi.cianlogic.com';
}
else if(hostname === 'prod.cianlogic.com') {
  backendHost = 'https://apiprod.cianlogic.com';
  socketHost = 'wss://apiprod.cianlogic.com';
}

export const APIURL = `${backendHost}`;
export const SOCKETURL = `${socketHost}`;
export const TZ = `timeZone=${currentTimezone}`;




