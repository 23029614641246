import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import "react-table-v6/react-table.css";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';
import { APIURL } from "../../Global";

const RecoveryStarToolPopup = ({ onClose, patientId }) => {
  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  const [recoveryData, setrecoveryData] = useState([]);
  const [startDate, setStartDate] = useState(localStorage.getItem("recoveryStartDate") || formatDateForInput(new Date()));
  const [endDate, setEndDate] = useState(formatDateForInput(new Date()));
  // const [endDate, setEndDate] = useState(localStorage.getItem("recoveryEndDate") || formatDateForInput(new Date()));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    try {
      const cleanDateString = dateString.replace(' IST+0530', '');
      const date = new Date(cleanDateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return dateString;
    }
  };

  const metricColors = {
    managing_mental_health: '#E57373', 
    self_care: '#42A5F5',               
    living_skills: '#FFB300',           
    social_networks: '#00897B',         
    work: '#7E57C2',                    
    relationships: '#FF7043',           
    addictive_behaviour: '#4CAF50',     
    responsibilities: '#EF5350',       
    identity_and_self_esteem: '#8BC34A',
    trust_and_hope: '#AB47BC'           
  };
  
  
  

  const fetchrecoveryData = async () => {
    setLoading(true);
    setError(null);
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    try {
      const response = await axios.get(`${APIURL}/api/v1/patient/assessment-results/`, {
        params: {
          patient_id: patientId,
          type: 'rst',
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          Authorization: "Token " + str,
        },
      });

      const formattedData = response.data.data.map(item => ({
        date: formatDate(item.date_of_administration),
        rawDate: item.date_of_administration,
        managing_mental_health: parseFloat(item.managing_mental_health) || 0,
        self_care: parseFloat(item.self_care) || 0,
        living_skills: parseFloat(item.living_skills) || 0,
        social_networks: parseFloat(item.social_networks) || 0,
        work: parseFloat(item.work) || 0,
        relationships: parseFloat(item.relationships) || 0,
        addictive_behaviour: parseFloat(item.addictive_behaviour) || 0,
        responsibilities: parseFloat(item.responsibilities) || 0,
        identity_and_self_esteem: parseFloat(item.identity_and_self_esteem) || 0,
        trust_and_hope: parseFloat(item.trust_and_hope) || 0,
        administered_by: item.administered_by
      }));

      setrecoveryData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
      setError('End date must be after start date');
      return;
    }

    setError(null);
    fetchrecoveryData();
  };

  useEffect(() => {
    fetchrecoveryData();
  }, []);

  useEffect(() => {
    localStorage.setItem("recoveryStartDate", startDate);
    localStorage.setItem("recoveryEndDate", endDate);
  }, [startDate, endDate]);

  const columns = [
    {
      Header: "Date & Time",
      accessor: "date",
      style: { backgroundColor: "rgba(0,0,0,0.1)" },
      minWidth: 200,
      sortMethod: (a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateB - dateA;
      }
    },
    {
      Header: "Managing Mental Health",
      accessor: "managing_mental_health",
      width: 210
    },
    {
      Header: "Self Care",
      accessor: "self_care",
      width: 120
    },
    {
      Header: "Living Skills",
      accessor: "living_skills",
      width: 120
    },
    {
      Header: "Social Networks",
      accessor: "social_networks",
      width: 150
    },
    {
      Header: "Work",
      accessor: "work",
      width: 100
    },
    {
      Header: "Relationships",
      accessor: "relationships",
      width: 150
    },
    {
      Header: "Addictive Behaviour",
      accessor: "addictive_behaviour",
      width: 200
    },
    {
      Header: "Responsibilities",
      accessor: "responsibilities",
      width: 150
    },
    {
      Header: "Identity & Self Esteem",
      accessor: "identity_and_self_esteem",
      width: 200
    },
    {
      Header: "Trust & Hope",
      accessor: "trust_and_hope",
      width: 150
    },
    {
      Header: "Done By",
      accessor: "administered_by",
      Cell: ({ value }) => value,
      minWidth: 150,
    }
  ];

  const chartData = {
    labels: recoveryData.map(entry => entry.date),
    datasets: [
      {
        label: 'Managing Mental Health',
        data: recoveryData.map(entry => entry.managing_mental_health),
        borderColor: metricColors.managing_mental_health,
        backgroundColor: metricColors.managing_mental_health,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Self Care',
        data: recoveryData.map(entry => entry.self_care),
        borderColor: metricColors.self_care,
        backgroundColor: metricColors.self_care,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Living Skills',
        data: recoveryData.map(entry => entry.living_skills),
        borderColor: metricColors.living_skills,
        backgroundColor: metricColors.living_skills,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Social Networks',
        data: recoveryData.map(entry => entry.social_networks),
        borderColor: metricColors.social_networks,
        backgroundColor: metricColors.social_networks,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Work',
        data: recoveryData.map(entry => entry.work),
        borderColor: metricColors.work,
        backgroundColor: metricColors.work,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Relationships',
        data: recoveryData.map(entry => entry.relationships),
        borderColor: metricColors.relationships,
        backgroundColor: metricColors.relationships,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Addictive Behaviour',
        data: recoveryData.map(entry => entry.addictive_behaviour),
        borderColor: metricColors.addictive_behaviour,
        backgroundColor: metricColors.addictive_behaviour,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Responsibilities',
        data: recoveryData.map(entry => entry.responsibilities),
        borderColor: metricColors.responsibilities,
        backgroundColor: metricColors.responsibilities,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Identity & Self Esteem',
        data: recoveryData.map(entry => entry.identity_and_self_esteem),
        borderColor: metricColors.identity_and_self_esteem,
        backgroundColor: metricColors.identity_and_self_esteem,
        fill: false,
        tension: 0.1
      },
      {
        label: 'Trust & Hope',
        data: recoveryData.map(entry => entry.trust_and_hope),
        borderColor: metricColors.trust_and_hope,
        backgroundColor: metricColors.trust_and_hope,
        fill: false,
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    scales: {
      y: {
        min: 0,
        max: 10,
        title: {
          display: true,
          text: 'Score',
          color: '#333',
          font: {
            size: 14,
            weight: 'bold',
          }
        },
        grid: {
          display: true,
          color: '#ddd',
          lineWidth: 1,
        },
        ticks: {
          color: '#333',
          font: {
            size: 12,
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date & Time',
          color: '#333',
          font: {
            size: 14,
            weight: 'bold',
          }
        },
        ticks: {
          autoSkip: true, // Disable auto skipping to ensure all dates are shown
          maxRotation: 0, // No rotation, keeping them horizontal
          minRotation: 0, // Keep the dates horizontal
          padding: 10,
          color: '#333', // Ticks color
          font: {
            size: 12, // Font size for the ticks
          }
        },
        grid: {
          display: false, // Hide X-axis grid lines for a cleaner interface
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 30,
          boxHeight: 10,
          usePointStyle: false,
          padding: 20,
          font: {
            size: 12
          },
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, i) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              lineWidth: 2,
              hidden: !chart.isDatasetVisible(i),
              datasetIndex: i
            }));
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 10,
        displayColors: true,
        boxWidth: 40,
        boxHeight: 20,
        usePointStyle: false
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };


  return (
    <div className="modal-overlay" style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 2000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "15px",
      paddingBottom: "15px"
    }}>
      <div className="modal-content" style={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: "8px",
        width: "100vw",
        height: "calc(100vh - 30px)",
        maxWidth: "1200px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column"
      }}>
        <div className="modal-header" style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
          borderBottom: "1px solid #ccc"
        }}>
          <button className="close-button" style={{
            cursor: "pointer",
            background: "none",
            border: "none",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#ccc"
          }} onClick={onClose}>
            ✖
          </button>
        </div>

        <div style={{
          padding: "10px 20px",
        }}>
          <h5 className="title-of-tasks">Recovery Star Tool</h5>
        </div>

        <div className="recovery-index-container" style={{ padding: "20px" }}>
          <div className="top-container" style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "20px",
            gap: "20px"
          }}>
            <div className="top-left-container" style={{
              flex: "1 1 60%",
              padding: "15px",
              borderRadius: "5px",
              height: "500px",
              overflowY: 'auto'
            }}>
              <div className="date-range-container" style={{
                display: "flex",
                gap: "10px",
                padding: "10px",
                marginBottom: "15px",
                alignItems: "center",
                flexWrap: "wrap"
              }}>
                <label>From:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ marginRight: "10px" }}
                />
                <label>To:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <button
                  onClick={handleCheck}
                  disabled={loading}
                  className='btn btn-primary btn-col'
                >
                  {loading ? "Loading..." : "Check"}
                </button>
              </div>
              {error && (
                <div style={{ color: 'red', marginBottom: '10px', padding: '5px' }}>
                  {error}
                </div>
              )}
              {recoveryData.length > 0 ? (
                <Line data={chartData} options={chartOptions} />
              ) : (
                <div style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#666'
                }}>
                  No data available
                </div>
              )}
            </div>

            <div className="top-right-container" style={{
  flex: "1 1 25%",
  padding: "10px",
  borderRadius: "8px",
  overflowY: "auto",
  border: "1px solid #ccc",
  maxHeight: "350px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff"
}}>
  <h6 style={{
    fontSize: "1rem",
    color: "#333",
    marginBottom: "10px",
    borderBottom: "1px solid #eee",
    paddingBottom: "5px",
    fontWeight: "600"
  }}>Assessment Summary</h6>

  {recoveryData.length > 0 ? (
    recoveryData.map((entry, index) => (
      <div key={index} style={{
        border: "1px solid #e0e0e0",
        borderRadius: "6px",
        padding: "8px",
        marginBottom: "4px",
        backgroundColor: "#fafafa",
        transition: "background-color 0.3s",
      }}>
        <p style={{ marginBottom: "0", color: "#777", fontSize: "0.85rem" }}>
          {entry.administered_by} <span style={{ fontStyle: "italic" }}>administered</span>
        </p>
        <p style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>{entry.date}</p>
        <p style={{ margin: "0 0 4px", color: "#333", fontSize: "0.95rem", fontWeight: "500" }}>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Managing Mental Health: {entry.managing_mental_health}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Self Care: {entry.self_care}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Living Skills: {entry.living_skills}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Social Networks: {entry.social_networks}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Work: {entry.work}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Relationships {entry.relationships}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Addictive Behaviour: {entry.addictive_behaviour}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Responsibilities: {entry.responsibilities}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Identity And Self Esteem: {entry.identity_and_self_esteem}</strong>
          <br/>
          <strong style={{ margin: "0 0 4px", color: "#555", fontSize: "0.9rem" }}>Trust And Hope: {entry.trust_and_hope}</strong>
          <br/>
        </p>
        
      </div>
    ))
  ) : (
    <div style={{ color: '#999', textAlign: 'center', fontSize: "0.95rem", paddingTop: "15px" }}>
      No data available
    </div>
  )}
</div>
</div>

<div className="table-container" style={{
marginTop: "20px",
maxHeight: "400px",
overflowY: "auto",
border: "1px solid #ddd",
borderRadius: "5px",
padding: "10px",
maxWidth: "800px", 
margin: "0 auto" 
}}>
<ReactTable 
columns={columns} 
data={recoveryData} 
defaultPageSize={10} 
className="-striped -highlight" 
resizable={true}
noDataText={loading ? "Loading..." : "No data available"}
/>
</div>

</div>
</div>
</div>
);
};

export default RecoveryStarToolPopup;